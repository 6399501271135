import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
// import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
// import Paper from '@material-ui/core/Paper';
import {TextField, Typography, Button, FormLabel,Toolbar} from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
//import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
// import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Media from './media';
import ImageControl from './shares/react_image_control';

import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';



// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';



// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }


class FileDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_document: {
        date: (new Date()).toString(),
        titleVN: '',
        titleEN: '',
        //titleKR: '',
        summaryVN: '',
        summaryEN: '',
        //summaryKR: '',
        filename: '',
        filetype: '',
        thumbnail: ''
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        titleVN_error: false,
        titleEN_error: false,
        //titleKR_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false
    }
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('document',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                //console.log(item[property]);
                data[property] = item[property];
              }
            }
            data._id = item._id;
            $this.setState({s_document: data},function(){
              //console.log($this.state.banner);
            });
          }
        });
      });
    }
  }
  handleFieldChange(e) {
    var $this = this,
        s_document = this.state.s_document; 
    s_document[e.target.id] = e.target.value;
    this.setState({s_document:s_document});
  }
  handleValidate(e,flag) {
    var $this = this,
        s_document = this.state.s_document,
        obj = this.state.error;
    
    s_document[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    
    this.setState({s_document: s_document, error:obj}, () => { 
    });
  }
  // selectImg(index,value){
  //   var $this = this,
  //       s_document = this.state.s_document;
  //   s_document.images[index] = value;
  //   this.setState({s_document:s_document},function(){
  //     $this.handleClose();
  //   });
  // }
  _save(){
    var $this = this;
    var data = this.state.s_document;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('document',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/document/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('document',data, function(res) {
        if (res.status == 'success') {
          $this.context.router.history.push('/document/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;
    
    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['titleVN_error'] = this.state.s_document.titleVN === '';
          obj['vn'] = obj['titleVN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['titleEN_error'] = this.state.s_document.titleEN === '';
          obj['en'] = obj['titleEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['titleKR_error'] = this.state.s_document.titleKR === '';
        //   obj['kr'] = obj['titleKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/document/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCover(value) {
    var $this = this,
        s_document = this.state.s_document; 
    s_document.thumbnail = value;
    this.setState({s_document:s_document});
  }
  handleFileUpdate(value) {
    var $this = this,
        s_document = this.state.s_document; 
    s_document.filename = value;
    s_document.filetype = Utils.getFileExtension(value);
    this.setState({s_document:s_document});
  }
  onEditorStateChange(field,value){
    var s_document = this.state.s_document;
    s_document[field] = value;

    this.setState({s_document:s_document});
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const s_document = this.state.s_document;
    const editorConfigBasic = Utils.setupBasicEditor();
    
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa tài liệu</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleVN" label={language.VN.title} className={classes.textField} value={s_document.titleVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.titleVN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="date" label="Thời gian" className={classes.textField} value={s_document.date} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <ImageControl label="Hình đại diện" value={s_document.thumbnail} handleChange={(value) => this.handleCover(value)}></ImageControl>
            <ImageControl label="Tài liệu" value={s_document.filename} handleChange={(value) => this.handleFileUpdate(value)}></ImageControl>
            <FormLabel className={classes.label_shrink}>{language.VN.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.s_document.summaryVN}
              onModelChange={(value)=>this.onEditorStateChange('summaryVN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleEN" label={language.EN.title} className={classes.textField} value={s_document.titleEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.titleEN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink}>{language.EN.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.s_document.summaryEN}
              onModelChange={(value)=>this.onEditorStateChange('summaryEN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleKR" label={language.KR.title} className={classes.textField} value={s_document.titleKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.titleKR_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink}>{language.KR.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.s_document.summaryKR}
              onModelChange={(value)=>this.onEditorStateChange('summaryKR',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

FileDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
FileDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(FileDetail);
